import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import './Chat.css';

function Chat({ onLogout }) {
  const { agentId } = useParams();
  const location = useLocation();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [error, setError] = useState('');
  const [name] = useState(location.state?.agentName || 'Agent');
  const [service, setService] = useState('');
  const [role, setRole] = useState('Unknown Role');
  const [model, setModel] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [answer, setAnswer] = useState('');
  const chatEndRef = useRef(null);
  const DID = localStorage.getItem('did');
  const API_URL = process.env.REACT_APP_API_URL;
  const AGENT_API_URL = process.env.REACT_APP_AGENT_API_URL;
  useEffect(() => {
    const fetchMessages = async () => {
      setLoadingMessages(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/get_chats?agent_id=${agentId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setMessages(response.data);
      } catch (error) {
        setError('Error fetching messages');
        onLogout();
      } finally {
        setLoadingMessages(false);
      }
    };
    fetchMessages();
  }, [agentId]);

  useEffect(() => {
    const services = location.state?.serviceName;
    const roles = location.state?.roleName;
    setService(services);
    setRole(roles);
    const modelId = location.state?.model;
    const model = modelId === '1' ? 'gpt-3.5' : modelId === '2' ? 'gpt-4' : '';
    setModel(model);
  }, [location.state]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, answer, loadingMessages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendingMessage(true);
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}/save_chat`,
        { agent_id: agentId, message, sender_type: 'user' },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const newMessage = {
        message,
        sender_type: 'user',
        timestamp: new Date().toISOString(),
      };
      setMessages([...messages, newMessage]);
      setMessage('');

      await handleFetchDataAgent(message);
    } catch (error) {
      setError('Error sending message');
    } finally {
      setSendingMessage(false);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const handleFetchDataAgent = async (text) => {
    try {
      const data = {
        input: {
          name,
          model,
          service,
          role,
          did: DID,
          input: text,
        },
        config: {},
      };

      await postDataAndStreamResponse(AGENT_API_URL, data);
    } catch (error) {
      setIsStreaming(false);
      console.error('Error in handleFetchDataAgent:', error);
    }
  };

  const postDataAndStreamResponse = async (url, data) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      let partialChunk = '';
      let latestMessage = '';
      setIsStreaming(true);
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        partialChunk += chunk;

        const lines = partialChunk.split('\n');
        for (let i = 0; i < lines.length - 1; i++) {
          try {
            const line = lines[i];
            if (line.startsWith('data: ')) {
              const jsonString = line.slice(6);
              const data = JSON.parse(jsonString);
              const answerData = data?.ops?.filter(
                (op) => op.op === 'replace' && op.path === '/final_output' && op.value
              );
              if (answerData && answerData.length > 0) {
                latestMessage =
                  answerData[answerData.length - 1]?.value?.replace(/^\n+|\n+$/g, '');
              }
            }
          } catch (error) {
            console.error('Error processing stream chunk:', error);
          }
        }
        partialChunk = lines[lines.length - 1];
      }

      if (partialChunk) {
        try {
          const data = JSON.parse(partialChunk.slice(6));
          const answerData = data?.ops?.filter(
            (op) => op.op === 'replace' && op.path === '/final_output' && op.value
          );
          if (answerData && answerData.length > 0) {
            latestMessage =
              answerData[answerData.length - 1]?.value?.replace(/^\n+|\n+$/g, '');
          }
        } catch (error) {
          console.error('Error processing final chunk:', error);
        }
      }

      if (latestMessage) {
        setAnswer(latestMessage);

        // Call API save_chat with sender is agent
        const token = localStorage.getItem('token');
        await axios.post(
          `${API_URL}/save_chat`,
          { agent_id: agentId, message: latestMessage, sender_type: 'agent' },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const newMessage = {
          message: latestMessage,
          sender_type: 'agent',
          timestamp: new Date().toISOString(),
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }
      setIsStreaming(false);
    } catch (error) {
      console.error('Error in postDataAndStreamResponse:', error);
      throw error;
    }
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="chat-container">
      <div className="chat-header ">
        <h1 className='main-tittle'>Communicate</h1>
        <span>
          <p className="text-02">
            Interact with secured, trusted, and bespoke Agents accountable to you and have your best interests in mind.
          </p>
        </span>
        <span>
          <p className="text-02">
            Problem: Effective AI - Human interfaces <br />
            Status: Ongoing
          </p>
        </span>
        <hr />
      </div>
      {error && <p>{error}</p>}
      {loadingMessages ? (
        <div className="spinner-message-container">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <>
          <div className="messages-container">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`message ${msg.sender_type === 'user' ? 'user-message' : 'agent-message'}`}
              >
                <div className="message-content">
                  <p>{msg.message}</p>
                </div>
                <span className="message-time">{formatDate(msg.timestamp)}</span>
              </div>
            ))}
            <div ref={chatEndRef}></div>
          </div>
          <div class="agent-info">
            <hr className='chat-info-devider' />
            <div class="agent-name">{name}</div>
            <div class="agent-role">{role} is your AI Speciallist Agent</div>
          </div>
          <form onSubmit={handleSubmit} className="form-group-chat">
            <div className="form-input-chat">
              <input
                type="text"
                className="form-control"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type a message..."
                disabled={sendingMessage || isStreaming}
              />
              {sendingMessage || isStreaming ? (
                <div className="spinner-border spinner-chat" role="status">
                  <span className="sr-only"></span>
                </div>
              ) : (
                <button
                  type="submit"
                  className="chat-btn"
                  disabled={!message.trim()} 
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="22" y1="2" x2="11" y2="13"></line>
                    <polygon points="22 2 18 21 11 13 2 9 22 2"></polygon>
                  </svg>
                </button>
              )}
            </div>
          </form>

        </>
      )}
      <div className='footer'>
        <hr className='footer-divider' />
        <p className='footer-infor'>© 2024 ⎈+V</p>
      </div>
    </div>
  );
}

export default Chat;
