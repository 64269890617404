import React, { useState } from 'react';
import axios from 'axios';
import './ConclusionInvest.css';

function ConclusionInvest() {
    const [loadingDemo, setLoadingDemo] = useState(false);
    const [loadingAssociate, setLoadingAssociate] = useState(false);
    const [successMessageDemo, setSuccessMessageDemo] = useState('');
    const [successMessageAssociate, setSuccessMessageAssociate] = useState('');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');


    const API_URL = process.env.REACT_APP_API_URL;

    const [form1Data, setForm1Data] = useState({
        name: '',
        recipient_name: '',
        recipient_email: '',
    });

    const [form2Data, setForm2Data] = useState({
        name: '',
        organization: '',
        message: '',
        intent_id: '',
    });

    const handleForm1Change = (e) => {
        setForm1Data({
            ...form1Data,
            [e.target.name]: e.target.value,
        });
    };

    const handleForm2Change = (e) => {
        setForm2Data({
            ...form2Data,
            [e.target.name]: e.target.value,
        });
    };

    const handleForm1Submit = async (event) => {
        event.preventDefault();
        setLoadingDemo(true);
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${API_URL}/create_demonstration`, form1Data, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setPopupMessage('Demonstration shared successfully!');
            setIsPopupOpen(true);
            setForm1Data({
                name: '',
                recipient_name: '',
                recipient_email: '',
            });
        } catch (error) {
            console.error('Error sharing demonstration:', error);
            setPopupMessage('Failed to share demonstration.');
            setIsPopupOpen(true);
        } finally {
            setLoadingDemo(false);
        }
    };

    const handleForm2Submit = async (event) => {
        event.preventDefault();
        setLoadingAssociate(true);
        const token = localStorage.getItem('token');
        const dataToSubmit = {
            ...form2Data,
            intent_id: Number(form2Data.intent_id),
        };
        try {
            await axios.post(`${API_URL}/create_associate`, dataToSubmit, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setPopupMessage('Thank you for your support!');
            setIsPopupOpen(true);
            setForm2Data({
                name: '',
                organization: '',
                message: '',
                intent_id: '',
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            setPopupMessage('Failed to submit form.');
            setIsPopupOpen(true);
        } finally {
            setLoadingAssociate(false);
        }
    };
    const closePopup = () => {
        setIsPopupOpen(false);
    };
    return (
        <div className='conclusion-invest-wrappper'>
            <div className='inner'>
                <div className='partner-sponser-invest-infor'>
                    <h1 className='main-tittle'>
                        Partner, Sponsor, Invest.
                    </h1>
                    <span>
                        <p className='text-02'>
                            Thank you for viewing this demonstration.<br />
                            There's plenty of work to do and we need your help.<br />
                            Your support will go towards our mission of democratizing machines for humanity.
                        </p>
                    </span>
                    <span>
                        <p className='text-02'>
                            Your sponsorship or investment will enable the next phases of:
                        </p>
                    </span>
                    <span>
                        <p className='text-02'>
                            - Selling these tools to governments and organizations around the world so they can track and trace AI advancements.<br />
                            - Sell these tools to media agencies, photographers, and journalists to ensure the uniqueness of real vs AI created content.<br />
                            - Further AI Agent payments infrastructure development and deployment.<br />
                            - Develop inter-agent collaboration and cooperation between user created agents.<br />
                            - Develop autonomous inter-agent transactions between user created agents.
                        </p>
                    </span>
                    <hr />
                </div>
                <span>
                    <p className='text-03'>
                        Share this demonstration
                    </p>
                </span>
                <form className='demonstration-container' onSubmit={handleForm1Submit}>
                    <input
                        required
                        type='text'
                        placeholder='Your name'
                        name='name'
                        value={form1Data.name}
                        onChange={handleForm1Change}
                    />

                    <input
                        required
                        type='text'
                        placeholder='Recipients Name'
                        name='recipient_name'
                        value={form1Data.recipient_name}
                        onChange={handleForm1Change}
                    />
                    <input
                        required
                        type='email'
                        placeholder='Recipients Email Address'
                        name='recipient_email'
                        value={form1Data.recipient_email}
                        onChange={handleForm1Change}
                    />
                    {loadingDemo ? (
                        <div className="spinner-border-text">
                            <div className="spinner-border" role="status"></div>
                        </div>
                    ) : (
                        <button className='send-btn'>
                            Send
                            <img className="icon-create" src="https://www.svgrepo.com/show/376370/arrow-right-line.svg" alt="logo" />
                        </button>
                    )}
                </form>
                {successMessageDemo && (
                    <p className="success-message">{successMessageDemo}</p>
                )}
                <span>
                    <p className='text-03'>
                        Partner, Sponsor, Invest
                    </p>
                </span>
                <form className='form-2' onSubmit={handleForm2Submit}>
                    <div className='field'>
                        <input
                            required
                            type='text'
                            placeholder='Your Name'
                            name='name'
                            value={form2Data.name}
                            onChange={handleForm2Change}
                        />
                    </div>
                    <div className='field'>
                        <input
                            required
                            type='text'
                            placeholder='Your Organization'
                            name='organization'
                            value={form2Data.organization}
                            onChange={handleForm2Change}
                        />
                    </div>
                    <div className='form-intent'>
                        <select
                            name='intent_id'
                            value={Number(form2Data.intent_id)}
                            onChange={handleForm2Change}
                            required
                        >
                            <option value=''>-Your intent-</option>
                            <option value="1">Sponsor</option>
                            <option value="2">Partner</option>
                            <option value="3">Invest</option>
                        </select>
                    </div>
                    <div className='field'>
                        <textarea
                            placeholder='Message'
                            name='message'
                            value={form2Data.message}
                            onChange={handleForm2Change}
                            required
                        ></textarea>
                    </div>
                    {loadingAssociate ? (
                        <div className="spinner-border-text">
                            <div className="spinner-border" role="status"></div>
                        </div>
                    ) : (
                        <button type='submit' className='send-btn'>
                            Send
                            <img className="icon-create" src="https://www.svgrepo.com/show/376370/arrow-right-line.svg" alt="logo" />
                        </button>
                    )}
                    {successMessageAssociate && (
                        <p className="success-message">{successMessageAssociate}</p>
                    )}
                </form>
                <div className='footer'>
                    <hr className='footer-divider' />
                    <p className='footer-infor'>© 2024 ⎈+V</p>
                </div>
            </div>
            {isPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <p>{popupMessage}</p>
                        <button className="close-btn" onClick={closePopup}>x</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ConclusionInvest;
