import React, { useEffect, useState } from 'react';
import { EmbeddedChat } from '@embeddedchat/react';

function RocketChat({}) {
	return (
		<div>
			<EmbeddedChat
				host='https://rocket-chat.tanika.ai'
				roomId='GENERAL'
				channelName='general'
				anonymousMode='false'
			/>
		</div>
	);
}

export default RocketChat;
