import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './Login.css';
import logo from '../assets/logo.png';

function Login({ setUsername, setDid }) {
  const [username, setUsernameInput] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/login`, { username, password });
      setMessage('Login successful');
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem('username', username);
      localStorage.setItem('did', response.data.did);
      setDid(response.data.did);
      setUsername(username);
      navigate('/');
    } catch (error) {
      setMessage('Username or password incorrect');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wrapper ">
      <div className='inner'>
        <img className='logo' src={logo} alt='logo' />
        <h1>AI Registry & Ledger</h1>
        <p className='text-02'>Welcome.<br />
          Inside are fundamental proof of concept tools. These core tools showcase the ability to assign traceable digital ID's to AI Agents and have them transact with each other (managed by humans for now).</p>
        <form className='form-login' onSubmit={handleSubmit}>
          <input type="text" className='user-name' placeholder='Username' value={username} onChange={(e) => setUsernameInput(e.target.value)} />
          <input type="password" className='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
          <button type="submit" className="login-btn" disabled={loading}>Login</button>
        </form>
        <Link to='/register' className='register-text'>Register to access</Link>
        {loading && (
          <div className="spinner-border-text">
            <div className="spinner-border" role="status"></div>
          </div>
        )}
        {message && <p className='error-msg'>{message}</p>}
      </div>
    </div>
  );
}

export default Login;
