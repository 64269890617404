import React from 'react';
import { NavLink } from 'react-router-dom';

function NavigationLinks() {
  return (
    <>
      <NavLink className="nav-link" to="/create-agent" >Create Agent</NavLink>
      <NavLink className="nav-link" to="/list-agents">Agent List</NavLink>
      <NavLink className="nav-link" to="/transactions">Transactions</NavLink>
      <NavLink className="nav-link" to="/conclusion-invest">Invest</NavLink>

    </>
  );
}

export default NavigationLinks;
