import React, { useEffect, useState } from 'react';
import './Profile.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Profile({ username, did, onLogout }) {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [balance, setBalance] = useState(null); 
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (!username) {
      onLogout();
      return;
    }

    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const userResponse = await axios.get(`${API_URL}/user`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setUserData(userResponse.data);
        const balanceResponse = await axios.get(`${API_URL}/get_balance`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        if (balanceResponse.data.message === 'No native tokens found for user.') {
          setBalance(0); 
        } else {
          setBalance(balanceResponse.data.native_token_balance); 
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
        if (error.response && error.response.status === 401) {
          onLogout();
        }
      }
    };

    fetchUserData();
  }, [username, API_URL, onLogout]);

  return (
    <div className="profile-wrapper">
      <h1 className='main-title'>Profile</h1>
      <div className="profile-container">
        <div className="profile-info">
          <p><strong>Username:</strong> {username}</p>
          <p><strong>DID:</strong> <a href={`https://explorer.tanika.ai/search/${did}`} target="_blank" rel="noopener noreferrer">View</a></p>
          <p><strong>User Wallet:</strong> <a href={`https://explorer.tanika.ai/search/${userData?.wallet_address}`} target="_blank" rel='noopener noreferrer'>View</a></p>
          <p><strong>Native Token Balance:</strong> {balance !== null ? balance : 'Loading...'}</p> 
        </div>
      </div>
      <div className="footer">
        <hr className="footer-divider" />
        <p className="footer-info">© 2024 ⎈+V</p>
      </div>
    </div>
  );
}

export default Profile; 
