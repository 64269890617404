import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AgentTransaction.css';

function AgentTransaction({ onLogout }) {
  const [agents, setAgents] = useState([]);
  const [receiverAgents, setReceiverAgents] = useState([]);
  const [senderAgent, setSenderAgent] = useState('');
  const [receiverAgent, setReceiverAgent] = useState('');
  const [amount, setAmount] = useState('');
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [walletBalance, setWalletBalance] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchAgentsById = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/list_agents`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const validAgents = response.data.filter(agent => agent.wallet_address);
      setAgents(validAgents);
    } catch (error) {
      console.error('Error fetching agents', error);
      onLogout();
    }
  };
  const fetchAllAgents = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/list_agent_all`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const validReceiverAgents = response.data.filter(agent => agent.wallet_address);
      setReceiverAgents(validReceiverAgents);
    } catch (error) {
      console.error('Error fetching all agents', error);
      onLogout();
    }
  };

  useEffect(() => {
    fetchAgentsById();
    fetchAllAgents();
    fetchTransactions();
  }, [API_URL, onLogout]);

  const fetchTransactions = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/list_transactions`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTransactionHistory(response.data);
    } catch (error) {
      console.error('Error fetching transactions', error);
    }
  };

  const handleSenderAgentChange = (e) => {
    const agentId = e.target.value;
    setSenderAgent(agentId);
    const selectedAgent = agents.find(agent => agent.id == agentId);
    if (selectedAgent) {
      setWalletBalance(selectedAgent.amount);
    } else {
      setWalletBalance('');
    }
  };

  const handleTransactionSubmit = async () => {
    if (senderAgent && receiverAgent && amount) {
      if (senderAgent === receiverAgent) {
        alert('Sender and Receiver cannot be the same.');
        return;
      }
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        await axios.post(
          `${API_URL}/transactions`,
          {
            sender_id: senderAgent,
            receiver_id: receiverAgent,
            amount,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSenderAgent('');
        setReceiverAgent('');
        setAmount('');
        setWalletBalance('');
        fetchTransactions();
        fetchAgentsById();
      } catch (error) {
        console.error('Error processing transaction', error);
      } finally {
        setLoading(false);
      }
    } else {
      alert('Please fill all fields');
    }
  };


  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = transactionHistory.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="transaction-container">
      <h1 className='main-tittle '>Agent to Agent Transactions
      </h1>
      <span>
        <p className='text-02'>
          We are working towards enabling economic infrastructure for AI Agent to AI Agent trade. Not autonomous for now, a human user oversees and initiates the transaction.<br />
          For this demo, each Agent is provided 1000 test tokens.<br />
          Try sending value to a third-party AI Agent on the platform using test value (TST).
        </p>
      </span>
      <span>
        <p className='text-02'>
          Problem: AI Agents inability to transact autonomously<br />
          Status: In progress
        </p>
      </span>
      <hr />
      <div className="transaction-form">
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="senderAgent">Sender Agent:</label>
            <select
              id="senderAgent"
              value={senderAgent}
              onChange={handleSenderAgentChange}
            >
              <option value="">Select Sender</option>
              {agents.map((agent) => (
                <option key={agent.id} value={agent.id}>
                  {agent.name} ({agent.amount} TST)
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="receiverAgent">Receiver Agent:</label>
            <select
              id="receiverAgent"
              value={receiverAgent}
              onChange={(e) => setReceiverAgent(e.target.value)}
            >
              <option value="">Select Receiver</option>
              {receiverAgents.map((agent) => (
                <option key={agent.id} value={agent.id}>
                  {agent.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="amount">Amount (TST):</label>
            <input
              type="number"
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </div>
        <button onClick={handleTransactionSubmit} className="agent-transaction-subtmit-btn" disabled={loading}>
          {loading ? 'Submitting...' : 'Submit'}
        </button>
        {loading && (
          <div className="spinner-border-text spinner">
            <div className="spinner-border" role="status"></div>
          </div>
        )}
      </div>

      <div className="list-transaction-container">
        <h1>Transaction History</h1>
        <p>Total Records: {transactionHistory.length}</p>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Sender</th>
              <th>Receiver</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {currentTransactions.map((transaction) => (
              <tr key={transaction.id}>
                <td>
                  <a href={`https://explorer.tanika.ai/search/${transaction.transaction_id}`} target="_blank" rel="noopener noreferrer">
                    View
                  </a>
                </td>
                <td>
                  <a href={`https://explorer.tanika.ai/search/${transaction.sender_wallet_address}`} target="_blank" rel="noopener noreferrer">
                    {transaction.sender_name}
                  </a>
                </td>
                <td>
                  <a href={`https://explorer.tanika.ai/search/${transaction.receiver_wallet_address}`} target="_blank" rel="noopener noreferrer">
                    {transaction.receiver_name}
                  </a>
                </td>
                <td>{transaction.amount}</td>
                <td>{new Date(transaction.date).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <nav>
          <ul className="pagination">
            {Array.from({ length: Math.ceil(transactionHistory.length / itemsPerPage) }, (_, i) => (
              <li key={i + 1} className="page-item">
                <button onClick={() => paginate(i + 1)} className="page-link">
                  {i + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <p className='text-02'>
          Your information is never shared with 3rd parties and is secured with the highest encryption.
        </p>
      </div>
      <div className='footer'>
        <hr className='footer-divider' />
        <p className='footer-infor'>© 2024 ⎈+V</p>
      </div>
    </div>
  );
}

export default AgentTransaction;
